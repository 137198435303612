/* Adjust the background of the entire MegaMenu */
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: white;
}
  .p-megamenu-panel{
    background: #f2f2f2;
    /* border: 1px solid #333; */
  }
  
  /* Adjust padding for the main list */
  .p-megamenu-root-list {
    padding: 10px;
  }
  
  /* Change the appearance of submenu headers */
  .p-megamenu-submenu-header {
    font-weight: bold;
    color: #000;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  /* Style submenu list items */
  .p-megamenu-submenu-list .p-menuitem-link {
    color: #333;
    padding: 10px;
  }
  .p-megamenu-panel .p-menuitem-link{
    color: #333;
    padding: 10px;
  }
  .p-megamenu .p-menuitem-link:hover, .p-megamenu-panel .p-menuitem-link:hover{
    color:#000;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text{
    color: #FFF;
  }
  svg.p-icon.p-submenu-icon {
    color: #fff;
}

.p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  background-color: deeppink;
}
.p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.08);
}